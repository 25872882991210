.Logo {
    font-weight: 900;
    font-size: 64px;
    margin: 0;
}


@media screen and (max-width: 800px) {
    .Logo {
        font-size: 32px;
    }
}