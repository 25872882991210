@import "../styles/colors";

.Timeline {
    border-top: 1px solid $border;
    ul {
        li {
            margin-bottom: 12px;
        }
    }
}
@media (min-width: 800px) {
    .Timeline .TimelineItems {
        padding: 0;
        gap: 10px 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}