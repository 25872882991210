@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@600&display=swap');
@import './styles/colors';

h1,
h2 {
  font-family: 'Grenze Gotisch', 'Lora', Georgia, serif;
}

body {
  margin: 0;
  font-family: 'Lora', Georgia, serif;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3 {
  margin: 12px 0;
}

.Half {
  color: $half;

  a {
    opacity: 0.7;
  }
}

.Columns>p {
  margin-bottom: 0;
}

p {
  margin: 16px 0;
  line-height: 1.5;
  text-align: justify;
}

body {
  background-color: $background;
  color: white;
}

button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-size: 20px;
  font-family: 'Lora', 'Georgia', serif;
  cursor: pointer;
  outline: none;
}

a:link,
a:visited,
button {
  text-decoration: none;
  color: #ffae00;
}

a:active,
button:active {
  color: #ab7500;
}

a:hover,
a.Active,
button:hover,
button.Active {
  text-decoration: underline;
}

@media screen and (prefers-color-scheme: light) {

  body {
    background-color: white;
    color: black;
  }

  a:link,
  a:visited,
  button {
    color: #ca5100;
  }


  a:active,
  button:active {
    color: #f18900;
  }

}

@media (min-width: 800px) {
  .Columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}

@media print {
  body {
    color: black;
  }
}