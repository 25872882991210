.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    box-sizing: border-box;
    min-height: 128px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    margin-bottom: 20px;

    .Tagline {
        margin: 0;
        font-weight: 400;
        font-size: 20px;
    }
}