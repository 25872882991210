.ChipList {

    .ListTitle {
        text-transform: uppercase;
        font-family: 'Lora', 'Georgia', serif;
        font-size: 16px;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-bottom: -10px;
    }

    .Chip {
        margin-right: 10px;
        margin-bottom: 10px;
        display: inline-block;
    }
}