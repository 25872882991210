@import "../styles/colors";

.Projects {
    .ProjectList {
        display: grid;
        gap: 20px;
        margin-bottom: 20px;

        .Project {
            border-radius: 4px;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .Image {
                background-size: cover;
                background-position: center;
                width: 100%;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 0;
                    padding-bottom: calc( 9 / 16 * 100% );
                }
            }

            .Content {
                flex-grow: 1;
                background-color: $foreground;
                padding: 20px;
                display: flex;
                flex-direction: column;

                h2 {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                }

                p {
                    flex-grow: 1;
                }

                .ChipList {
                    a {
                        margin-right: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (prefers-color-scheme: light) {
    .Projects .ProjectList .Project .Content {
        background-color: $foreground-light;
    }
}

@media screen and (min-width: 1000px) {
    .Projects {
        .ProjectList {
            grid-template-columns: 1fr 1fr;
        }
    }
}