@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@600&display=swap);
h1,h2{font-family:"Grenze Gotisch","Lora",Georgia,serif}body{margin:0;font-family:"Lora",Georgia,serif;font-size:20px}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}h1,h2,h3{margin:12px 0}.Half{color:#969696}.Half a{opacity:.7}.Columns>p{margin-bottom:0}p{margin:16px 0;line-height:1.5;text-align:justify}body{background-color:#222;color:#fff}button{background:none !important;border:none;padding:0 !important;font-size:20px;font-family:"Lora","Georgia",serif;cursor:pointer;outline:none}a:link,a:visited,button{text-decoration:none;color:#ffae00}a:active,button:active{color:#ab7500}a:hover,a.Active,button:hover,button.Active{text-decoration:underline}@media screen and (prefers-color-scheme: light){body{background-color:#fff;color:#000}a:link,a:visited,button{color:#ca5100}a:active,button:active{color:#f18900}}@media(min-width: 800px){.Columns{display:grid;grid-template-columns:1fr 1fr;grid-gap:40px;gap:40px}}@media print{body{color:#000}}
.App{max-width:1000px;margin:0 auto;padding:0 20px}
.Logo{font-weight:900;font-size:64px;margin:0}@media screen and (max-width: 800px){.Logo{font-size:32px}}
.Header{display:flex;align-items:center;justify-content:space-between;padding:20px 0;box-sizing:border-box;min-height:128px;border-bottom:1px solid rgba(128,128,128,.2);margin-bottom:20px}.Header .Tagline{margin:0;font-weight:400;font-size:20px}
.Navigation ul{list-style-type:none;padding:0;margin:0}.Navigation ul li{display:inline;margin:0 10px}@media print{.Navigation{display:none}}
.Projects .ProjectList{display:grid;grid-gap:20px;gap:20px;margin-bottom:20px}.Projects .ProjectList .Project{border-radius:4px;position:relative;overflow:hidden;display:flex;flex-direction:column}.Projects .ProjectList .Project .Image{background-size:cover;background-position:center;width:100%}.Projects .ProjectList .Project .Image::before{content:"";display:inline-block;width:1px;height:0;padding-bottom:56.25%}.Projects .ProjectList .Project .Content{flex-grow:1;background-color:#444;padding:20px;display:flex;flex-direction:column}.Projects .ProjectList .Project .Content h2{display:flex;justify-content:space-between;margin:0}.Projects .ProjectList .Project .Content p{flex-grow:1}.Projects .ProjectList .Project .Content .ChipList a{margin-right:10px;margin-bottom:10px}@media screen and (prefers-color-scheme: light){.Projects .ProjectList .Project .Content{background-color:#ddd}}@media screen and (min-width: 1000px){.Projects .ProjectList{grid-template-columns:1fr 1fr}}
.Star{display:inline-block;height:14px;width:14px;fill:red}
.Chip{box-sizing:border-box;padding:5px 14px;height:36px;background-color:rgba(100,100,100,.2);border-radius:18px;-webkit-user-select:none;-ms-user-select:none;user-select:none}.Chip .Star{margin-left:4px}
.Button{background-color:rgba(0,0,0,0);border:none}
.ChipList .ListTitle{text-transform:uppercase;font-family:"Lora","Georgia",serif;font-size:16px}.ChipList ul{list-style-type:none;padding:0;margin:0;margin-bottom:-10px}.ChipList .Chip{margin-right:10px;margin-bottom:10px;display:inline-block}
.Avatar{width:200px;height:200px;max-width:100%;border-radius:50%;border:1px solid rgba(100,100,100,.2)}.Wrapper{-webkit-user-select:none;-ms-user-select:none;user-select:none;display:flex;justify-content:center;position:relative}.Wrapper::before{display:block;content:"";height:8px;top:50%;z-index:-1;width:100%;position:absolute;border-top:solid 1px rgba(100,100,100,.2);border-bottom:solid 1px rgba(100,100,100,.2)}
.Timeline{border-top:1px solid rgba(100,100,100,.2)}.Timeline ul li{margin-bottom:12px}@media(min-width: 800px){.Timeline .TimelineItems{padding:0;grid-gap:10px 40px;gap:10px 40px;display:grid;grid-template-columns:1fr 1fr}}

