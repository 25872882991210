.Navigation {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: inline;
            margin: 0 10px;
        }
    }
}

@media print {
    .Navigation {
        display: none;
    }
}